<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 9.68579C14.2523 9.68579 16.0781 7.85995 16.0781 5.60767C16.0781 3.35538 14.2523 1.52954 12 1.52954C9.74771 1.52954 7.92188 3.35538 7.92188 5.60767C7.92188 7.85995 9.74771 9.68579 12 9.68579Z" fill="currentColor" />
        <path d="M20.25 9.68574C21.6739 9.68574 22.8281 8.53148 22.8281 7.10762C22.8281 5.68376 21.6739 4.52949 20.25 4.52949C18.8261 4.52949 17.6719 5.68376 17.6719 7.10762C17.6719 8.53148 18.8261 9.68574 20.25 9.68574Z" fill="currentColor" />
        <path d="M3.75 9.68574C5.17386 9.68574 6.32812 8.53148 6.32812 7.10762C6.32812 5.68376 5.17386 4.52949 3.75 4.52949C2.32614 4.52949 1.17188 5.68376 1.17188 7.10762C1.17188 8.53148 2.32614 9.68574 3.75 9.68574Z" fill="currentColor" />
        <path d="M6.29016 12.0009C5.27531 11.1695 4.35623 11.2795 3.18281 11.2795C1.42781 11.2795 0 12.6989 0 14.4431V19.5624C0 20.3199 0.618281 20.9358 1.37859 20.9358C4.66106 20.9358 4.26562 20.9952 4.26562 20.7942C4.26562 17.1668 3.83597 14.5066 6.29016 12.0009Z" fill="currentColor" />
        <path d="M13.1161 11.2982C11.0665 11.1273 9.28505 11.3002 7.74844 12.5686C5.17702 14.6282 5.67188 17.4015 5.67188 20.7942C5.67188 21.6918 6.40219 22.4357 7.31344 22.4357C17.2079 22.4357 17.6017 22.7549 18.1884 21.4556C18.3809 21.0162 18.3281 21.1558 18.3281 16.9523C18.3281 13.6136 15.4372 11.2982 13.1161 11.2982Z" fill="currentColor" />
        <path d="M20.8172 11.2795C19.6373 11.2795 18.7233 11.1706 17.7098 12.0009C20.1457 14.4879 19.7344 16.9666 19.7344 20.7942C19.7344 20.9964 19.4061 20.9357 22.5722 20.9357C23.3597 20.9357 24 20.2978 24 19.5136V14.4431C24 12.6989 22.5722 11.2795 20.8172 11.2795Z" fill="currentColor" />
    </svg>
</template>
